import FormPage from '@/components/FormPage'
import { DateTime } from 'luxon'
import {
  formatAddress,
  formatPhoneNumber,
  getStateFromMyKad,
} from '@/utils'
import {
  createGetParams,
  createPostParams,
} from '@/rest'

const formTitle = 'Prepaid Registration'

export default {
  extends: FormPage,
  props: ['id'],
  data () {
    return {
      agree: false,
      notification: false,
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      debounce: 1500,
      idType: 'NewNRIC',
      searchTimeout: null,
      step: 1,
      title: formTitle,
      // Form steps
      registrationDetails: {
        fields: {
          // msisdn: {
          //   component: () => import(/* webpackChunkName: "msisdnField" */ '@/components/MsisdnField'),
          //   name: 'Msisdn',
          //   label: 'Enter Msisdn',
          // },
          // iccid: {
          //   component: () => import(/* webpackChunkName: "spField" */ '@/components/SpField'),
          //   name: 'Iccid',
          //   label: 'Enter Iccid',
          // },
          msisdnList: {
            name: 'Msisdn list',
            component: () => import(/* webpackChunkName: "spFieldPrepaid" */ '@/components/SpFieldPrepaid'),
            props: {
              items: [],
            },
          },
          ugu: {
            component: () => import(/* webpackChunkName: "msisdnField" */ '@/components/MsisdnField'),
            name: 'User-Gets-User',
            props: {
              existing: true,
              hint: 'Mobile number of the referrer',
              label: 'Enter UGU',
              noSelf: true,
              noDealer: true,
              ugu: true,
            },
          },
        },
        subTitle: 'Mobile number selections',
        title: 'Registration Details',
      },
      profile: {
        fields: {
          name: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Full name',
            props: {
              hint: 'Exactly as in Id card',
              label: 'Enter full name',
              persistentHint: true,
              required: true,
              rules: [
                val => !!val || 'Full name required!',
              ],
            },
          },
          id: {
            component: () => import(/* webpackChunkName: "personalIdField" */ '@/components/PersonalIdField'),
            name: 'MyKad',
            props: {
              label: 'Enter MyKad number',
              // hint: 'MyKad/Passport/Armed Force number',
              rules: [
                val => !!val || 'MyKad number required!',
                val => (val && val.length === 12) || 'Invalid MyKad',
              ],
            },
          },
          dob: {
            component: () => import(/* webpackChunkName: "dobField" */ '@/components/DobField'),
            name: 'Date of birth',
            props: {
              required: true,
            },
          },
        },
        subTitle: 'Subscriber identity',
        title: 'Profile',
      },
      contactDetails: {
        fields: {
          contactNumber: {
            component: () => import(/* webpackChunkName: "phoneField" */ '@/components/PhoneField'),
            name: 'Contact number',
            props: {
              hint: 'Contactable land or mobile number',
              label: 'Enter phone number',
              required: true,
              rules: [
                val => !!val || 'Contact number required!',
              ],
            },
          },
          email: {
            // component: () => import(/* webpackChunkName: "emailField" */ '@/components/EmailField'),
            component: () => import(/* webpackChunkName: "unrestrictedEmailField" */ '@/components/UnrestrictedEmailField'),
            name: 'Email',
            props: {
              clearable: true,
              label: 'Enter email address',
              rules: [],
              validateOnBlur: true,
              required: true,
            },
            validate: () => {},
          },
          street1: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Street',
            props: {
              label: 'Enter street',
              required: true,
              rules: [val => !!val || 'Street required!'],
              validateOnBlur: true,
            },
            section: 'Address on MyKad',
          },
          street2: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: '',
            props: {
              label: 'Enter line 2 of street',
            },
          },
          city: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'City',
            props: {
              label: 'Enter city',
              required: true,
              rules: [val => !!val || 'City required!'],
            },
          },
          postalCode: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Postal code',
            props: {
              label: 'Enter postal code',
              mask: '#####',
              maxlength: 5,
              required: true,
              rules: [
                val => !!val || 'Postal code required!',
                val => (val && val.length === 5) || 'Invalid postal code!',
              ],
              validateOnBlur: true,
            },
          },
          state: {
            component: () => import(/* webpackChunkName: "stateProvinceField" */ '@/components/StateProvinceField'),
            name: 'State',
            props: {
              required: true,
              rules: [val => !!val || 'State required!'],
            },
          },
        },
        subTitle: 'Contact & billing information',
        title: 'Contact details',
      },
      submission: {
        fields: {

          ugu: {
            name: 'UGU',
          },
          name: {
            name: 'Name',
          },
          id: {
            name: 'MyKad',
          },
          dob: {
            name: 'Date of birth',
          },
          contactNumber: {
            name: 'Contact number',
          },
          email: {
            name: 'Email',
          },
          address: {
            name: 'Address',
          },
          msisdnSummary: {
            name: 'Msisdn list',
            component: () => import(/* webpackChunkName: "msisdnSummarySp" */ '@/components/MsisdnSummarySp'),
            props: {
              items: [],
            },
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    // Override
    formSteps: function () {
      return {
        registrationDetails: this.registrationDetails,
        profile: this.profile,
        contactDetails: this.contactDetails,
        submission: this.submission,
      }
    },
    registrationItems: function () {
      return this.registrationDetails.fields.msisdnList.props.items
    },
    address () {
      const address = formatAddress(this.contactDetails.fields)
      return address
    },
  },
  watch: {
    agree: function (val) {
      val && this.doSubmit()
    },
    step: function (val) {
      this.profile.fields.ocr.props.step = val
    },
    'registrationDetails.fields.ugu.value': function (val) {
      this.submission.fields.ugu.value = formatPhoneNumber(val)
    },
    'profile.fields.name.value': function (val) {
      this.submission.fields.name.value = val
    },
    'profile.fields.dob.value': function (val) {
      this.submission.fields.dob.value = val
    },
    'profile.fields.maidenName.value': function (val) {
      this.submission.fields.maidenName.value = val
    },
    'contactDetails.fields.contactNumber.value': function (val) {
      this.submission.fields.contactNumber.value = formatPhoneNumber(val)
    },
    'contactDetails.fields.email.value': function (val) {
      this.submission.fields.email.value = val
    },
    'profile.fields.id.value': function (val) {
      this.submission.fields.id.value = val
      if (!val || val.length < 12) return

      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const myKad = {
        dob: val.substr(0, 6),
        state: val.substr(6, 2),
      }
      const yy = Number(myKad.dob.substr(0, 2))
      const mm = myKad.dob.substr(2, 2)
      const dd = myKad.dob.substr(4, 2)
      let year = 1900 + yy

      this.submission.fields.id.value = myKad.dob + '-' + myKad.state + '-' + val.substr(8)
      if (now.year - year > 100) year = 2000 + yy
      myKad.dob = String(year) + '-' + mm + '-' + dd

      this.profile.fields.dob.validate && this.profile.fields.dob.validate()
      this.profile.fields.dob.value = myKad.dob

      const state = getStateFromMyKad(val)
      if (state) {
        if (state === 'ArmedForcedID') {
          this.idType = state
        } else {
          this.idType = 'NewNRIC'
        }
      } else this.idType = 'NewNRIC'
    },
    'contactDetails.fields.street1.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.street2.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.city.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.postalCode.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.state.value': function () {
      this.submission.fields.address.value = this.address
    },
  },
  async mounted () {
    this.submission.fields.msisdnSummary.props.items = this.registrationDetails.fields.msisdnList.props.items
    // this.contactDetails.fields.billingAddress.fields = this.billingAddress

    if (this.id) {
      const params = createGetParams({
        portId: this.id,
      })

      this.$rest.get('getMnpPayload.php', params).then(function (response) {
        const data = response.data

        this.registrationDetails.fields.ugu.value = data.ugu
        this.profile.fields.name.value = data.name
        this.profile.fields.id.value = data.id
        this.idType = data.idType
        this.profile.fields.dob.value = data.dob
        this.contactDetails.fields.contactNumber.value = data.contact
        this.contactDetails.fields.email.value = data.email
        this.contactDetails.fields.street1.value = data.street1
        this.contactDetails.fields.street2.value = data.street2
        this.contactDetails.fields.city.value = data.city
        this.contactDetails.fields.postalCode.value = data.postalCode
        this.contactDetails.fields.state.value = data.state
      }.bind(this)).catch(() => {
      })
    }
  },
  methods: {
    agreePdpa: function (val) {
      this.pdpaDialog = false
      if (!val) {
        this.status = this.submission.status = 'error'
        this.submission.errorMessage = 'You must ask the customer to agree to the Personal Data Protection Act before submitting the registration.'
        this.submission.submit = false
      }
      this.agree = val
    },
    // Override
    submit: function () {
      this.pdpaDialog = true
    },
    async doSubmit () {
      const payload = {
        ugu: this.registrationDetails.fields.ugu.value,
        name: this.profile.fields.name.value,
        id: this.profile.fields.id.value,
        idType: this.idType, // this.profile.fields.idType.value,
        dob: this.profile.fields.dob.value,
        contact: this.contactDetails.fields.contactNumber.value,
        email: this.contactDetails.fields.email.value,
        street1: this.contactDetails.fields.street1.value,
        street2: this.contactDetails.fields.street2.value,
        city: this.contactDetails.fields.city.value,
        postalCode: this.contactDetails.fields.postalCode.value,
        state: this.contactDetails.fields.state.value,
        sp: [],
        // newNumber: [],
      }
      this.registrationDetails.fields.msisdnList.props.items.forEach(item => {
        payload.sp.push({
          msisdn: item.msisdn,
          iccid: item.iccid,
        })
      })

      const params = createPostParams({
        action: 'registerPrepaid',
        payload: payload,
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to register Starter Pack numbers was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
          this.agree = false
        })
      }
    },
  },
}
